import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Slice
import { setActiveLoginModal } from '../../app/features/userSlice';
// Components
import Login from '../Login';
import Container from '../Container';
// Services
import { getSectionsInstitutions } from '../../services/getSectionsInstitutions';
// Images
import { IoMdClose } from 'react-icons/io';
import imageLogin from '../../Images/Login/loginImage.webp';
// Styles
import './LoginModal.css';

const LoginModal = () => {
  const dispatch = useDispatch();
  const { institutionCode } = useSelector((state) => state.institution);

  const dataMetasearcher = getSectionsInstitutions(institutionCode)[0].data;
  const dataIndependient = getSectionsInstitutions(
    institutionCode
  )[1].data.filter((objeto) => objeto.type === 'independient');

  const data = [...dataMetasearcher, ...dataIndependient];

  return (
    <div className='loginHomeModal'>
      <Container>
        <div className='modal-content'>
          <div className='textContent'>
            <div>
              <h2>
                Exercitationem qui quod ullam etLorem ipsum dolor sit amet. At
                quasi
              </h2>
              <p>
                Having some informational elements, even basic and simple, is
                essential for conducting an effective search
              </p>
            </div>

            <div className='modalResources'>
              {data.map((section, i) => {
                return (
                  <div key={i}>
                    <img src={section.icon} alt={section.title} />
                    <p>{section.description}</p>
                  </div>
                );
              })}
            </div>
            <Login />
          </div>
          <div className='imageContent'>
            <div className='gradiente'></div>
            <img src={imageLogin} alt='Modal login' />
          </div>
          <button
            className='closeButton'
            onClick={() => dispatch(setActiveLoginModal(false))}
          >
            <IoMdClose />
          </button>
        </div>
      </Container>
    </div>
  );
};

export default LoginModal;
