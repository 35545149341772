import React, { useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
// Components
import Topic from '../../Components/Topic';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Container from '../../Components/Container';
import CarouselNews from '../../Components/CarouselNews';
import AcademicResources from '../../Components/AcademicResources';
import CarouselSectionsHome from '../../Components/CarouselSectionsHome';
import DataBaseSource from '../../Components/DataBaseSource';
import LoginModal from '../../Components/LoginModal';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// Slice
import {
  setUserEmail as setUserEmailAction,
  setUserName as setUserNameAction,
  setUserLastname as setUserLastnameAction,
  setUserCode as setUserCodeAction,
  setIpLogin as setIpLoginAction,
} from '../../app/features/userSlice';
// Services
import { getIpUser } from '../../services/getIpUser';
import { getInfoUserMoodle } from '../../services/getInfoUserMoodle';
import { getInfoUserCanvas } from '../../services/getInfoUserCanvas';
// Images
import sectionTutoringImg from '../../Images/HomePage/SectionTutoring_img.webp';
// IntroJs
import introJs from 'intro.js';
// Styles
import 'intro.js/introjs.css';
import './HomePage.css';

const HomePage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  // Slices
  const { activeLoginModal, ip_login, lms } = useSelector(
    (state) => state.user
  );
  const { base_news } = useSelector((state) => state.news);
  const { resourceType } = useSelector((state) => state.metasearch);
  const { databases, topics, institutionCode } = useSelector(
    (state) => state.institution
  );

  useEffect(() => {
    getIpUserInit();
    getUserInfo();

    if (localStorage.introShown === undefined) {
      location.pathname === '/' &&
        introJs()
          .setOption('dontShowAgain', true)
          .setOptions({
            showProgress: true,
            showBullets: false,
          })
          .start();
      localStorage.setItem('introShown', 'true');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const getIpUserInit = () => {
    if (ip_login === '') {
      getIpUser()
        .then((data) => {
          dispatch(setIpLoginAction(data));
        })
        .catch((error) => {
          console.error('Request error:', error);
        });
    }
  };

  const getUserInfo = () => {
    const currentUrl = new URL(window.location.href);
    const searchParams = new URLSearchParams(currentUrl.search);

    if (searchParams.size > 0) {
      const codeParam = searchParams.get('code');
      const userParam = searchParams.get('user');

      if (codeParam) {
        searchParams.delete('code');
        currentUrl.search = searchParams.toString();
        window.history.replaceState({}, '', currentUrl.href);
        dispatch(setUserCodeAction(codeParam));

        if (lms === 'moodle') {
          getInfoUserMoodle(codeParam, institutionCode)
            .then((data) => {
              if (data !== null) {
                data.data.email &&
                  dispatch(setUserEmailAction(data.data.email));
                data.data.firstname &&
                  dispatch(setUserNameAction(data.data.firstname));
                data.data.lastname &&
                  dispatch(setUserLastnameAction(data.data.lastname));
              }
              resourceType && navigate(resourceType);
            })
            .catch((error) => {
              console.error('Request error:', error);
            });
        }

        if (lms === 'canvas') {
          getInfoUserCanvas(codeParam, institutionCode)
            .then((data) => {
              if (data !== null) {
                data.data.email &&
                  dispatch(setUserEmailAction(data.data.email));
                data.data.first_name &&
                  dispatch(setUserNameAction(data.data.first_name));
                data.data.last_name &&
                  dispatch(setUserLastnameAction(data.data.last_name));
              }
              resourceType && navigate(resourceType);
            })
            .catch((error) => {
              console.error('Request error:', error);
            });
        }
      }
      if (userParam) {
        searchParams.delete('user');
        currentUrl.search = searchParams.toString();
        window.history.replaceState({}, '', currentUrl.href);
        dispatch(setUserEmailAction(userParam));
      }
    }
  };

  console.log('activeLoginModal', activeLoginModal);

  return (
    <>
      <Header />
      {activeLoginModal && <LoginModal />}

      <main>
        <section className='metasearchHome'>
          <Container>
            <div
              className='metasearchHomeContainer'
              data-title='Welcome to our Website! 👋'
              data-intro="We're excited to have you here. Let's get started with a quick tour of our site's key features."
              data-step='1'
            >
              <h1
                data-title='Congratulations!'
                data-intro="You've completed the onboarding process. Enjoy your journey with us, and remember, we're always here to assist you!"
                data-step='6'
              >
                The <b>easiest</b> way to Find <b>knowledge</b>
              </h1>
              <p>
                Search any book, magazine or article in hundreds of library data
                bases around the world
              </p>
            </div>
          </Container>
        </section>

        <section>
          <Container className='sectionInstitutions'>
            <p>
              Your <b>All-in-One</b> Gateway to Explore Knowledge
            </p>
            <CarouselSectionsHome />
          </Container>
        </section>

        {institutionCode !== 'GAPAT' && institutionCode !== 'DECO_LOVERS' && (
          <section className='topicsHome'>
            <Container>
              <div className='topicsHomeContainer'>
                <h2>
                  Find hundreds of sources <b>searching by topics</b>
                </h2>
                <div className='topicsClassButtonContainer'>
                  {topics &&
                    topics?.map((topic, i) => {
                      return (
                        <Topic key={i} name={topic.name} path={topic.path} />
                      );
                    })}
                </div>
              </div>
            </Container>
          </section>
        )}

        <AcademicResources />

        <section className='dictionaryHome'>
          <Container className='dictionaryHomeContainer'>
            <h2>
              Use our <b>Dictionary</b> to find any{' '}
              <b>specialized definition</b>
            </h2>
            <p>
              Having some informational elements, even basic and simple, is
              essential for conducting an effective search and understanding the
              information properly
            </p>
            <Link to='/dictionary'>
              <button className='goToDictionaryButton'>Go to Dictionary</button>
            </Link>
          </Container>
        </section>

        <section className='databasesHome'>
          <Container>
            <h2>
              More than <b>150,874 sources</b> in just <b>one site</b>
            </h2>
            <div className='databasesHomeLogoContainer'>
              {databases &&
                databases?.map((dataBase, i) => {
                  return <DataBaseSource key={i} dataBase={dataBase} />;
                })}
            </div>
          </Container>
        </section>

        {institutionCode === 'CMVC' && (
          <Container>
            <section className='tutoringHome'>
              <img
                className='tutoringImage'
                src={sectionTutoringImg}
                alt='TUTORING'
              />

              <div>
                <div className='tutoringHomeContainer'>
                  <h2 className='tutoringTitle'>
                    Schedule a <b>tutoring</b> <br /> <b>session</b>
                  </h2>
                  <p className='tutoringText'>
                    We have dedicated Mentors who will help you get over any
                    doubts you feel and help you succeed in your academic
                    studies
                  </p>
                  <Link className='goToTutoring' to='/tutoring'>
                    <p>Shedule now</p>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='icon icon-tabler icon-tabler-arrow-right'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      strokeWidth='1.5'
                      stroke='#000000'
                      fill='none'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M5 12l14 0' />
                      <path d='M13 18l6 -6' />
                      <path d='M13 6l6 6' />
                    </svg>
                  </Link>
                </div>
              </div>
            </section>
          </Container>
        )}

        <section className='newsHome'>
          <Container className='newsHomeContainer'>
            <h2 className='newsHomeContainerTitle'>
              Discover all the <b>scientific news</b>
            </h2>
            <p className='newsHomeContainerText'>
              Stay up to date with news and updates. Explore our virtual library
              to expand your knowledge
            </p>
            <div className='carouselNewsInfoPage'>
              <CarouselNews news={base_news.slice(0, 4)} />
            </div>
          </Container>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default HomePage;
