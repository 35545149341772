import React from 'react';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
// Slice
import { setActiveLoginModal } from '../../app/features/userSlice';
// Style
import './Login.css';

const Login = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  // const { navigate } = useNavigate();

  const { requestLogin } = useSelector((state) => state.institution);
  const { user_email, user_name } = useSelector((state) => state.user);

  const currentlyRoute = pathname.replace('/', '');

  const userLogged = () => {
    if (currentlyRoute === '') {
      return <button className='loginButton'>Welcome, {user_name}</button>;
    } else {
      return <button className='loginButton'>Welcome, {user_name}</button>;
    }
  };

  if (requestLogin) {
    if (user_email?.length > 0) {
      return userLogged();
    } else {
      return (
        <button
          onClick={() => dispatch(setActiveLoginModal(true))}
          className='loginButton'
        >
          Login
        </button>
      );
    }
  } else {
    return <button className='loginButton noLogin'>Login</button>;
  }
};

export default Login;
