import React from 'react';
// Components
import Login from '../Login';
// import Container from '../Container';
import ArtSearchBar from '../ArtSearchBar';
import Metasearcher from '../Metasearcher';
import JobsSearchBar from '../JobsSearchBar';
// Redux
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
// Services
import { getSectionsInstitutions } from '../../services/getSectionsInstitutions';
// Styles
import './SearchBar.css';

const SearchBar = () => {
  const { pathname } = useLocation();
  const currentlyRoute = pathname.replace('/', '');

  // Slices
  const { institutionCode } = useSelector((state) => state.institution);
  const sectionsMetasearcher = getSectionsInstitutions(
    institutionCode
  )[0].data.map((objeto) => objeto.route);

  const handleMetasearchBar = () => {
    if (sectionsMetasearcher.includes(currentlyRoute)) {
      return <Metasearcher />;
    } else if (currentlyRoute === 'art') {
      return <ArtSearchBar />;
    } else if (currentlyRoute === 'jobs') {
      return <JobsSearchBar />;
    } else {
      return null;
    }
  };

  if (currentlyRoute !== '') {
    return (
      <div className='searchBar'>
        <div className='searchBarContainer'>
          {handleMetasearchBar()}
          <div className='metasearcherLogin'>
            <Login />
          </div>
        </div>
      </div>
    );
  }
};

export default SearchBar;
